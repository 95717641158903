/* You can add global styles to this file, and also import other style files */
@use "font";

@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  font-family: 'Inter', Arial, sans-serif;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

.pac-container {
  border-radius: 5px;
}

a {
  @apply text-detera-red-800 hover:text-detera-red-600;
}

.card {
  @apply bg-white rounded-3xl border-detera-border-dark border;
}

.btn-inverted {
  @apply border hover:bg-detera-red-600 font-semibold hover:text-white border-detera-red-800 rounded-3xl text-xs text-detera-red-800 px-4 py-1 flex items-center;
}

.btn-primary {
  @apply border bg-detera-red-800 cursor-pointer hover:bg-detera-red-600 font-semibold text-white border-detera-red-800 rounded-3xl text-xs px-4 py-1 flex items-center;
}

.btn-dark {
  @apply bg-detera-blue-800 cursor-pointer hover:bg-detera-blue-600 font-semibold text-white rounded-3xl text-xs px-4 py-1 flex items-center;
}

.dropdown-filter {
  .dx-popup-content {
    padding: 10px;
  }
}

.dx-texteditor-label {
  @apply text-gray-400;
}

.offer-details-drawer {
  .dx-state-readonly {
    .dx-texteditor-input {
      padding-top: 19px !important;
    }
  }
}

.createOfferPropertyList {
  .dx-list-item {
    @apply border-t-0;
  }

  .dx-list-item {
    @apply cursor-default;
  }

  .dx-list-item-content {
    @apply px-0 py-1;
  }
}

.tagBoxReadOnly {
  .dx-tag-content {
    @apply text-[12px] text-left;
  }
}

.tagBoxReadOnly.dx-state-readonly {
  .dx-texteditor-input-container {
    @apply grid;
  }

  .dx-texteditor-input {
    @apply hidden;
  }

  .dx-tag-content {
    @apply bg-transparent text-black text-[12px]  text-left p-0;
  }

  .dx-tag-remove-button {
    @apply hidden;
  }
}

.dropDownFirstHighlighted{
  .dx-list-item:first-child{
    border-bottom: 1px solid #dee1e3;
  }
}

.marketsStartEndDropdown{
  .dx-overlay-content{
    width: 170px !important;
  }
}

.customGrid {

  .dx-datagrid .dx-header-filter {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .dx-datagrid-rowsview .dx-row {
    height: 40px !important; /* Set your desired height */
  }

  /* Show the header filter icon on hover */
  .dx-datagrid .dx-header-row .dx-datagrid-action:hover .dx-header-filter {
    opacity: 1;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-action {
    height: 26px; /* Adjust the height value as needed */
    line-height: 26px; /* Align text vertically */
  }

  .dx-datagrid-headers {
    @apply rounded-t-xl border-b-0;
  }

  .dx-datagrid-rowsview {
    @apply border-t-0;
  }

  .dx-datagrid-content dx-row > td, .dx-row > tr > td {
    @apply border-detera-border;
    border: 1px solid #E6E6E6;
  }
}

#comparables-filter,
#offers-filter {

  .dx-selectbox,
  .dx-dropdownbox {
    border-color: transparent;

    .dx-selectbox-container,
    .dx-dropdowneditor-input-wrapper {


      background-color: #F5F5F5;
      border-radius: 10px;
    }

    .dx-texteditor-input {
      padding-left: 14px;
    }

    .dx-placeholder {
      color: #5A5A5A !important;
      padding-left: 5px;
    }
  }
}

.dx-state-readonly * {
  cursor: default !important;
  border-style: none !important;
}

.dx-texteditor-input {
  font-size: 12px;
}

.property-name {
  .dx-texteditor-input {
    font-size: 40px;
  }
}

.gmnoprint[role=menubar] {
  margin-bottom: 24px !important;
}

.comparables-property-list {
  .dx-list-select-checkbox-container {
    padding-right: 50px !important;
  }
}

textarea {
  padding: 7px 9px 8px;
  color: #333;
  font-size: 12px;
  border-radius: 4px;
  min-height: 36px;
  border: 1px solid #dee1e3;
  width: 100%;
  overflow-y: hidden;
}

.dx-empty-message {
  @apply border-0 text-center pt-12;
  font-size: 14px !important;
}

.gm-style-iw-d {
  overflow: auto !important;
  padding-right: 20px;
}

.gm-style-iw {
  @apply border border-detera-red-800 text-detera-red-800;
  padding: 7px 14px !important;
}

.gm-style-iw-chr {
  height: 0;

  button {
    margin: -16px -20px 0 0 !important;

    span {
      height: 18px !important;
      width: 18px !important;
    }
  }
}


.dx-tag-content {
  display: block;
}

//.dx-button-content {
//  .dx-icon {
//    @apply text-white;
//  }
//}

.dx-header-row {
  font-size: 12px !important;
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  border: 1px solid #dee1e3 !important;
}

.dx-data-row {
  height: 100px !important;

  td {
    vertical-align: middle !important;
  }
}

.marketsList{
  .dx-data-row {
    height: 60px !important;
  }
}

.label-title{
  @apply text-[16px] font-medium text-detera-gray-400;
}

.cell-text {
  @apply overflow-ellipsis overflow-hidden text-sm text-detera-gray-800;
}

.cell-label {
  @apply text-gray-400 text-xs pb-1;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.tagBoxFilter .dx-list-select-all {
  display: none;
}

.dx-tag-container {
  width: 100%;
  padding-right: 8px;
  padding-left: 4px;
}

#offerCountChart {
  width: 100%;
  height: 120px;
}

.fileuploaderNoPadding {
  .dx-fileuploader-wrapper {
    padding: 0 !important;
  }

  .dx-fileuploader-input-wrapper {
    border: 0 !important;
  }

  .dx-fileuploader-show-file-list .dx-fileuploader-files-container {
    padding: 0 !important;
  }

  .dx-fileuploader-files-container {
    padding: 0 !important;
  }
}

.dx-button-mode-contained.dx-state-focused {
  @apply bg-detera-red-800;
}

.dx-dropdownlist-popup-wrapper {
  .dx-list-item.dx-state-focused {
    background-color: rgba(234, 123, 129, 0.5) !important;
  }
}


.dxSwitchHeight {
  height: 30px;
  /* Set your desired height here */
  line-height: 30px;
  /* Ensure the line height matches the height */
  padding: 0;
}

.dxSwitchHeight .dx-switch-container {
  height: 100%;
  padding: 0;
  vertical-align: baseline;
}

.dxSwitchHeight .dx-switch-handle {
  margin-top: 0;
  height: 100%;
}

.dxSwitchHeight .dx-switch-off {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dxSwitchHeight .dx-switch-on {
  display: flex;
  justify-content: center;
  align-items: center;
}


.dxSwitchHeight .dx-switch-inner {
  height: 100%;
  padding: 0;
}


.svg-hover-color:hover {
  filter: brightness(0) saturate(100%) invert(82%) sepia(8%) saturate(1246%) hue-rotate(308deg) brightness(99%) contrast(93%);
}

.svg-hover-color-active {
  filter: brightness(0) saturate(100%) invert(71%) sepia(54%) saturate(2306%) hue-rotate(305deg) brightness(94%) contrast(95%) !important;
}


.offersList {
  .dx-list-item-content {
    @apply p-0;
  }
}

.dxSwitchModern {
  height: 30px !important;
  width: 50px !important;


  .dx-switch-container {
    @apply rounded-full border-none;

    background-color: #808080 !important;

    height: 30px !important;
    width: 50px !important;
  }

  /* Apply this if the switch is in the 'on' state */
  &.dx-switch-on-value .dx-switch-container {
    background-color: #ef969a !important;
  }

  .dx-switch-handle::before {
    background-color: white !important;
    border-radius: 99999px;
  }

  .dx-switch-handle {
    flex-basis: 25px;
    width: 25px;
    height: 25px;
    margin-top: 2px;
  }
}

.contactsList{
  .dx-list-item {
    cursor: default !important; /* Disable pointer cursor */
    background: transparent !important; /* Remove hover background */
  }

  .dx-list-item:hover {
    background: transparent !important; /* Prevent hover effect */
  }
}

.dxListNoMinHeight.dx-list{
    min-height: 0 !important;
}
